import logo from './logo.png';
import './App.css';

function App() {
  return (
 <div>
    <div class="header">
     <div class="row">
            <div class="column left">
                <img src={logo} class="header image" alt="Kriyas Technologies Ltd" />
            </div>
            <div class="column right h2">
                <h2 >Kriyas Technologies Ltd</h2>
            </div>
      </div>
    </div>
    <div class="row content">
          <div class="column"/>
          <div class="column x">
            <b class="b">Domain Expertise</b>
            <p class="p">
                We provide IT contracting services in various business domains including but not limited to
                <ul>
                    <li>Publishing</li>
                    <li>Media</li>
                    <li>Finance</li>
                    <li>Telecom</li>
                    <li>Retail</li>
                    <li>Finance</li>
                </ul>
            </p>

            <b class="b">Business Analysis</b>
            <p class="p">
                Our experienced consultants also speicalised in offering business and technical analysis services.
            </p>
          </div>

          <div class="column y">
            <b class="b">Technologies</b>
            <p class="p">
              Our consultants are experienced in providing solutions and implementation  of highly scalable microservices, web development and enterprice applications using various technologies  including
              <ul>
                  <li>Programming Languages: JAVA, Kotlin, Groovy, Go</li>
                  <li>NoSql, Relational Databases</li>
                  <li>Kafka</li>
                  <li>Cloud experience: AWS, AZURE</li>
                  <li>CI/CD: Jenkins</li>
                  <li>Containerization and Orchestration: Docker, Kubernetes</li>
                  <li>Microservices using latest web frameworks Spring, Micronaut etc</li>
              </ul>
            </p>
          </div>
          <div class="column x">
            <b class="b">Training</b>
            <p class="p">
                We can also offer training in below technologies and methodologies
                <ul>
                    <li>Programming Languages: JAVA, Kotlin, Groovy, Go</li>
                    <li>Cloud: AWS</li>
                    <li>Business Analysis</li>
                    <li>Agile</li>
                </ul>
            </p>
          </div>
         <div class="column"/>
    </div>
    <div class="footer">
      <p><b>Email: contact@kriyastechnologies.com</b></p>
    </div>
</div>
  );
}

export default App;
